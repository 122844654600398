import CssTextField from './CssTextField';
import React,{useState} from 'react'

export default function Fields(props) {
        const [shrink,setShrink] = useState(false)
        let Icon = props.Icon ? props.Icon : '';
    return (
      
            <CssTextField
                style={{marginTop: '17px'}}
                fullWidth
                // id="outlined-number"
                id={props.label}
                label={shrink ? props.label : props.value !== '' ? props.label : props.Label ? props.Label : props.label}
                variant='outlined'
                type={props.type ? props.type : "text"}
                value={props.value}
                onFocus={()=>setShrink(true)}
                disabled={props.disabled || false}
                onBlur={()=>setShrink(!shrink)}
                onChange={(e)=> props?.handleChange(props.name,e.target.value)}
                placeholder={props.placeholder}
                // InputLabelProps={{
                //         shrink: shrink,
                //         }}
                inputProps={{
                    // ...params.inputProps,
                    autoComplete: `${props.type === 'password' ? 'new-password' : "off"}`,
                }}
                InputProps={{
                    endAdornment: (
                       props.Icon ?  Icon : ''
                    )
                }}
                validators={props.validators}
                errorMessages={props.errorMessages}
            />
    )
  }