
import React, { useState, useEffect } from "react";
import { Avatar, Card, Grid, Typography, Divider } from "@mui/material";
import DefaultHealthDeptIcon from "../../../../../../icons/Healthcare_Department.svg";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import axios from "axios";


export const headers1 = () => {
    return (
        {
            headers: {
                'X-FHIR-TENANT-ID': 'docsteth',
                'X-FHIR-DSID': 'fhirDocsteth',
                'Realm': 'docsteth'
            }
        }
    )
}

const ParticularPublicOverviewDept = ({ departmentId }) => {

    const [departOrgInfo, setDepartOrgInfo] = useState(null);
    const [departLocInfo, setDepartLocInfo] = useState(null);
    const [showAbout, setShowAbout] = useState(false);
    const [about, setabout] = useState(false);
    const [editAboutText, setEditAboutText] = useState('');


    useEffect(() => {
        setDepartOrgInfo(departmentId)
        if (departmentId?.id) {
            getLocationByOrgId(departmentId?.id)
        }

    }, [departmentId?.id])


    const getLocationByOrgId = (orgId) => {
        axios.get(process.env.REACT_APP_FHIR_SERVER + `Location?organization=` + orgId, headers1()).then(res => {
            console.log(res.data)
            let data = res.data?.entry?.[0]?.resource
            if (data?.id) {
                setDepartLocInfo(data)
            }
        }).catch(() => {
            setDepartLocInfo(null)
        })
    }


    return (
        <div>
            <Card sx={{ padding: "10px", height: "50px", boxShadow: "0px 0px 1.6px #888", borderRadius: "10px" }}>
                <Grid container spacing={1} alignItems="center">

                    <Grid item xs={11} sm={11} md={11}>
                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>

                            <div>
                                <Avatar variant="square" sx={{
                                }} src={departOrgInfo?.identifier?.filter((info) => info["system"]?.includes("logo") && info["value"] && info["value"]?.startsWith("https"))?.[0]?.value || DefaultHealthDeptIcon} />

                            </div>
                            <div>
                                <div className="capitalize">
                                    <Typography className="capitalize" sx={{
                                        fontSize: "18px", fontWeight: 600, color: "#3084BE",
                                        "&.MuiTypography-root": {
                                            lineswhiteSpace: 0,
                                            fontFamily: "unset"
                                        }

                                    }}> {departOrgInfo?.name}</Typography>
                                </div>
                                <div className="fntSm capitalize">{departOrgInfo?.alias?.[0]}</div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>

                        <div className="flx-center justify-between">

                            {/* 
                            <div>
                                <img width="18px" height="18px" className="cursor" src={Settings} onClick={handleOpnHealthDept}></img>
                            </div> */}
                        </div>


                    </Grid>
                </Grid>


            </Card>
            {
                departLocInfo?.description && (

                    <div className="mt1-5" >
                        <div sx={{}}>
                            <Card sx={{ boxShadow: "0px 0px 1.6px #888", borderRadius: "10px" }}>
                                <div style={{ padding: '10px' }}>
                                    <div style={{ padding: '0px 8px' }}>
                                        <div className="font-head fntSemiBold flx-center">

                                            <Typography className="capitalize" sx={{
                                                fontSize: "18px", fontWeight: 600, color: "#3084BE",
                                                "&.MuiTypography-root": {
                                                    lineswhiteSpace: 0,
                                                    fontFamily: "unset"
                                                }

                                            }}> About &nbsp;&nbsp;</Typography>


                                        </div>
                                    </div>



                                    {departLocInfo?.description && (
                                        <Typography my={1} sx={{ padding: '0px 8px' }}>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: departLocInfo?.description }}

                                                style={{
                                                    display: !showAbout ? '-webkit-box' : "",
                                                    WebkitBoxOrient: 'vertical ',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    WebkitLineClamp: 2,
                                                    lineswhiteSpace: 'normal',
                                                    whiteSpace: 'pre-wrap'
                                                }}

                                            />
                                        </Typography>
                                    )}

                                </div>
                                <Divider />
                                {departLocInfo?.description?.length > 400 &&
                                    <div
                                        style={{ padding: '8px', cursor: 'pointer', fontWeight: 'bold' }}
                                        onClick={() => setabout(!about)}
                                    // onMouseEnter={() => setHover(true)}
                                    // onMouseLeave={() => setHover(false)}
                                    >
                                        {
                                            !showAbout ?
                                                <div className='flx-center justify-center' onClick={() => setShowAbout(true)} >
                                                    <div  >
                                                        Show More
                                                    </div>
                                                    <KeyboardArrowDown style={{ marginTop: '3px' }} />
                                                </div>
                                                :
                                                <div className='flx-center justify-center' onClick={() => setShowAbout(false)}>
                                                    <div > Show Less </div>
                                                    <KeyboardArrowUp style={{ marginTop: '3px' }} />
                                                </div>

                                        }

                                    </div>
                                }
                            </Card>
                        </div>
                    </div>

                )
            }

        </div>
    )
}

export default ParticularPublicOverviewDept;