
import React, { useState, useEffect } from "react";
import { Avatar, Card, Grid, Typography, Divider } from "@mui/material";
import DefaultHealthDeptIcon from "../../../../../../icons/Healthcare_Department.svg";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import DefaultHealthServiceIcon from "../../../../../../icons/Healthcare_Service.svg"
import axios from "axios";

import Communicate from "../../../../../../images/Communication-language.svg";
const headers1 = () => {
    return (
        {
            headers: {
                'X-FHIR-TENANT-ID': 'docsteth',
                'X-FHIR-DSID': 'fhirDocsteth',
                'Realm': 'docsteth'
            }
        }
    )
}

const ParticularPublicOverviewClinical = ({ clinicalInfo }) => {

    const [clinicalOrgInfo, setClinicalOrgInfo] = useState(null);
    const [clinicalLocInfo, setClinicalLocInfo] = useState(null);
    const [showAbout, setShowAbout] = useState(false);
    const [about, setabout] = useState(false);
    const [editAboutText, setEditAboutText] = useState('');

    useEffect(() => {
        setClinicalOrgInfo(clinicalInfo)
        // if (clinicalInfo?.id) {
        //     getLocationByOrgId(clinicalInfo?.id)
        // }

    }, [clinicalInfo?.id])


    // const getLocationByOrgId = (orgId) => {
    //     axios.get(process.env.REACT_APP_FHIR_SERVER + `Location?organization=` + orgId, headers1()).then(res => {
    //         console.log(res.data)
    //         let data = res.data?.entry?.[0]?.resource
    //         if (data?.id) {
    //             setClinicalLocInfo(data)
    //         }
    //     }).catch(() => {
    //         setClinicalLocInfo(null)
    //     })
    // }
    return (
        <div>



            <Card sx={{ padding: "10px", height: "60px", boxShadow: "0px 0px 1.6px #888", }} className="mt1">
                <Grid container spacing={1} alignItems="center">

                    <Grid item xs={11} sm={11} md={11}>
                        <div style={{ display: "flex", alignItems: "center", gap: 10, borderRadius: "5px" }}>

                            <div>
                                <Avatar variant="square" sx={{

                                }} src={clinicalOrgInfo?.photo?.url || DefaultHealthServiceIcon} />

                            </div>
                            <div>

                                <div className="capitalize">
                                    <Typography className="capitalize" sx={{
                                        fontSize: "18px", fontWeight: 600, color: "#3084BE",
                                        "&.MuiTypography-root": {
                                            lineswhiteSpace: 0,
                                            fontFamily: "unset"
                                        }

                                    }}>{clinicalOrgInfo?.name}</Typography>
                                </div>
                                <div className="fntSm capitalize">{clinicalOrgInfo?.comment}</div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>

                    </Grid>
                </Grid>
            </Card>
            {/* 
            {
                departLocInfo?.description && (

                    <div className="mt1-5" >
                        <div sx={{}}>
                            <Card sx={{ boxShadow: "0px 0px 1.6px #888", borderRadius: "10px" }}>
                                <div style={{ padding: '10px' }}>
                                    <div style={{ padding: '0px 8px' }}>
                                        <div className="font-head fntSemiBold flx-center">

                                            <Typography className="capitalize" sx={{
                                                fontSize: "18px", fontWeight: 600, color: "#3084BE",
                                                "&.MuiTypography-root": {
                                                    lineswhiteSpace: 0,
                                                    fontFamily: "unset"
                                                }

                                            }}> About &nbsp;&nbsp;</Typography>


                                        </div>
                                    </div>



                                    {departLocInfo?.description && (
                                        <Typography my={1} sx={{ padding: '0px 8px' }}>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: departLocInfo?.description }}

                                                style={{
                                                    display: !showAbout ? '-webkit-box' : "",
                                                    WebkitBoxOrient: 'vertical ',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    WebkitLineClamp: 2,
                                                    lineswhiteSpace: 'normal',
                                                    whiteSpace: 'pre-wrap'
                                                }}

                                            />
                                        </Typography>
                                    )}

                                </div>
                                <Divider />
                                {departLocInfo?.description?.length > 400 &&
                                    <div
                                        style={{ padding: '8px', cursor: 'pointer', fontWeight: 'bold' }}
                                        onClick={() => setabout(!about)}
                                    // onMouseEnter={() => setHover(true)}
                                    // onMouseLeave={() => setHover(false)}
                                    >
                                        {
                                            !showAbout ?
                                                <div className='flx-center justify-center' onClick={() => setShowAbout(true)} >
                                                    <div  >
                                                        Show More
                                                    </div>
                                                    <KeyboardArrowDown style={{ marginTop: '3px' }} />
                                                </div>
                                                :
                                                <div className='flx-center justify-center' onClick={() => setShowAbout(false)}>
                                                    <div > Show Less </div>
                                                    <KeyboardArrowUp style={{ marginTop: '3px' }} />
                                                </div>

                                        }

                                    </div>
                                }
                            </Card>
                        </div>
                    </div>

                )
            } */}



            {
                clinicalOrgInfo &&

                <div className="mv1">
                    <Card sx={{ padding: "10px" }}>
                        <div className="font-head fntSemiBold">Service Details </div>
                        {clinicalOrgInfo?.specialty?.length > 0 &&
                            <Grid container alignItems="center" pt={1} xs={12}>
                                <Grid item>
                                    <div className="fntSm fntSemiBold">Speciality:</div>
                                </Grid>
                                <Grid item>
                                    <div className="fntSm flx-center"> &nbsp;{clinicalOrgInfo?.specialty ? clinicalOrgInfo?.specialty?.map((e) => e.coding?.[0]?.display + "," + " ") : ""}</div>
                                </Grid>
                            </Grid>
                        }
                        <Grid container alignItems="center" xs={12} pt={1}>
                            <Grid item>
                                <div className="flx-center fntSm "> <img src={Communicate} />&nbsp;<span>Communicate in</span> &nbsp;<span style={{ color: "#5465AA" }}>Telugu,English,Hindi</span></div>
                            </Grid>
                        </Grid>


                        <Grid container justifyContent="space-between" alignItems="center" pt={1} xs={12} >
                            {/* Category */}
                            {clinicalOrgInfo?.category?.[0]?.coding?.[0]?.display &&
                                <Grid item  >
                                    <Grid item container alignItems="center">
                                        <Grid item>
                                            <div className="fntSm fntSemiBold">Category:</div>
                                        </Grid>
                                        <Grid item>
                                            <div className="fntSm flx-center">
                                                &nbsp;{clinicalOrgInfo?.category ? clinicalOrgInfo?.category?.[0]?.coding?.[0]?.display : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            {/* Type */}

                            <Grid item>
                                {clinicalOrgInfo?.type?.[0]?.coding?.[0]?.display &&
                                    <Grid item container alignItems="center">
                                        <Grid item>
                                            <div className="fntSm fntSemiBold">Type:</div>
                                        </Grid>
                                        <Grid item>
                                            <div className="fntSm flx-center">
                                                &nbsp;{clinicalOrgInfo?.type ? clinicalOrgInfo?.type?.[0]?.coding?.[0]?.display : ""}
                                            </div>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {/* {locationList?.length > 0 &&
                            <Grid container pt={1} xs={12} >
                                <Grid item>
                                    <div className="pb0-5">
                                        <div className="pv0-5 fntSm fntSemiBold">Location Area</div>
                                        {locationList?.length > 0 &&
                                            <div className="flx-center" style={{ gap: 10 }} >
                                                {locationList?.map((e) => (
                                                    <div key={e?.id} style={{ backgroundColor: "#E2DDDD", borderRadius: "15px", height: "25px" }} className="ph1 fntSm flx-center">
                                                        {e?.name}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        } */}
                        {clinicalOrgInfo?.characteristic?.length > 0 &&
                            <Grid container pt={1} xs={12}>
                                <Grid item>
                                    <div className="pb0-5">
                                        <div className="fntSm fntSemiBold">Service characteristics</div>
                                        {clinicalOrgInfo?.characteristic?.length > 0 &&
                                            <div className="flx-center" style={{ gap: 10 }}>
                                                {clinicalOrgInfo?.characteristic?.map((e) => (
                                                    <div className="fntSm" style={{ color: "#C5A5E7" }}>
                                                        #{e?.coding?.[0]?.display}
                                                    </div>

                                                ))}
                                            </div>
                                        }

                                    </div>
                                </Grid>

                            </Grid>
                        }

                    </Card>
                </div>


            }
        </div>
    )
}
export default ParticularPublicOverviewClinical;