import React, { useState, useEffect } from 'react'
import HealthDeptIcon from "../../../../../../icons/Healthcare_Department.svg"
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Card, Divider, Grid } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { DOCS_WITHOUT_TOKEN_WITH_TENANTS } from '../../../../../../services/APIService';
import { URI } from '../../../../../utility/uri';
import { useSelector } from 'react-redux';
import "../../../../../utility/style.css"

const ListOfPublichealthDepts = ({orgInfo,updateTabVal,setselectedDeptItem}) => {

    const [listOfPublicHealthDept, setListOfPublicHealthDept] = useState([]);
    const [showMoreDepts, setShowMoreDepts] = useState(false);

    const orgData = useSelector(state => state.registration.userOrg)
    
    const location = useSelector(state => state.registration.location_withouttoken)


    useEffect(()=>{
        if(orgData?.id){
            getPublicDeptsList(orgData?.id);
        }
    },[orgData?.id])


    const showMoreCopyOfHealthDepts = () => {
        setShowMoreDepts(true)
    }

    const showLessCopyOfHealthDepts = () => {
        setShowMoreDepts(false)
    }

    const getPublicDeptsList = (orgId) => {
      
        if(orgId){
            DOCS_WITHOUT_TOKEN_WITH_TENANTS.get(`${URI.getHealthCareDeptListWithOutToken}${orgId}`).then((res) => {
                if(res.data?.entry){
                    setListOfPublicHealthDept(res.data?.entry); 
                }else{
                    setListOfPublicHealthDept([]);  
                }
            }).catch((err) => {
                setListOfPublicHealthDept([]); 
                console.log(err)
            })
        }
     
    }

  
    return (
        <div>
            <div className='mt1-5' sx={{}}>
                <Card sx={{ padding: "10px", borderRadius: "10px" }}>
                    <div >
                        <div className='flx  justify-between' >
                            <div style={{ color: "#3084BE", fontSize: "18px", fontWeight: 600 }} className='capitalize'>
                                Healthcare Department
                            </div>
                            <div className='flx  justify-between'>
                                {/* {
                                    (listOfPublicHealthDept?.length >= 2 && isAdmin === "PERMIT") && <MenuIcon className="cursor" sx={{ width: "20px", height: "20px", color: "#750083", '.MuiSvgIcon-root': { width: "20px", height: "20px" } }}
                                        onClick={"handleOpenRearrangeDeptDialog"} />
                                } */}
                                {/* {isAdmin === "PERMIT" && <AddCircleRoundedIcon className="cursor" sx={{ display: "flex", alignItem: "center", color: "#750083", width: "20px", height: "20px", '.MuiSvgIcon-root': { width: "20px", height: "20px" }, paddingLeft: "10px", paddingTop: "3px" }}
                                    onClick={"handleaddHealthDeptDialog"} />} */}
                            </div>
                        </div>
                    </div>

                    {
                        listOfPublicHealthDept?.length > 0 &&
                        <div style={{ width: "100%" }}>
                            {
                                <Grid container spacing={2}>
                                    {
                                        listOfPublicHealthDept?.length > 0 && listOfPublicHealthDept?.slice(0, showMoreDepts ? undefined : 4).map((val) => {

                                            return (
                                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                                    <Card sx={{ padding: "5px", boxShadow: "0px 0px 1.6px #888", borderRadius: "", height: { sm: "45px", md: "45px", lg: "45px" } }} className='mv1 cursor'  onClick={()=>{
                                                        updateTabVal("2");  
                                                        setselectedDeptItem(val?.resource);
                                                    }} >

                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                            <div>
                                                                <Avatar variant="square" sx={{
                                                                    width: '40px !important', height: '40px !important',

                                                                }}
                                                                    src={val?.resource?.identifier?.filter((val) => val["system"] == `${process.env.REACT_APP_API_URL}v1/logo` && val["value"]?.startsWith("https"))?.[0]?.value || HealthDeptIcon} />
                                                            </div>

                                                            <div style={{ height: "40px", width: "100%" }}>
                                                                <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.name}</div>
                                                                <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.alias?.[0]}</div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                            )
                                        }
                                        )
                                    }

                                </Grid>
                            }
                        </div>
                    }

                    {listOfPublicHealthDept?.length > 4 &&
                        <div type="label" style={{ textAlign: 'center' }}>
                            {listOfPublicHealthDept?.length > 4 && (
                                <>
                                    <Divider />
                                    {/* <div style={{ paddingTop: '5px', cursor: 'pointer', fontWeight: 'bold', background: hoverEffectforDepts ? "#F4F2EE" : "" }} */}
                                    <div style={{ paddingTop: '5px', cursor: 'pointer', fontWeight: 'bold', background: "" }}
                                    >
                                        {showMoreDepts ?
                                            <div className='flx-center justify-center' onClick={showLessCopyOfHealthDepts}>
                                                <div> Show Less</div>
                                                <KeyboardArrowUp style={{ marginTop: '3px' }} />
                                            </div>
                                            :
                                            <div className='flx-center justify-center' onClick={showMoreCopyOfHealthDepts} >
                                                <div> Show More </div>
                                                <KeyboardArrowDown style={{ marginTop: '3px' }} />
                                            </div>
                                        }
                                    </div>
                                </>
                            )}
                        </div>
                    }


                    {/* <AddHealthcareDept opn={openHealthDept} cls={handleaddHealthDeptDialog} /> */}
                </Card>


            </div>

        </div>
    )
}

export default ListOfPublichealthDepts