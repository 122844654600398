import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { AddCircle, MedicalServices, RemoveCircle } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// import { getHealthServices, updateHealthcare, updateLocation, updateLocationAmenities } from '../../../../common/functions/orgfun';
import { ValidatorForm } from "react-form-validator-core";
// import { FormDivider, Actions } from '../../../../common/components/layout';
// import ScheduleAvailability from '../scheduleAvailability';
// import { D_API } from '../../../../../services/APIService';
// import { getLocation } from '../../../../../redux/Actions/regActions';
import { useParams } from "react-router-dom";
// import ScheduleAvailabilityAmenities from './ScheduleAvailabilityAmenities';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { D_API } from "../../../../services/APIService";
import ScheduleAvailabilityAmenities from "./amenities/ScheduleAvailabilityAmenities";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";

const header = ["Days of Week", "", "Opening Time", "Closing Time", ""];

const weeks = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const TimeInput = ({ handleChange, handleSubmitAmenities, ...props }) => {
  const value = props.value;
  return (
    <TextValidator
      id="time"
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      value={value || ""}
      // onChange={e => handleDateChange('startTime', e.target.value, index)}
      onChange={handleChange}
      type="time"
      fullWidth
      validators={["required"]}
      errorMessages={["Select Time"]}
    />
  );
};

const WorkTable = ({ week, submitTime, data }) => {
  const [check, setCheck] = useState(null);
  const [time, setTime] = useState([]);
  const [fields, setFields] = useState({ start: null, end: null });

  console.log(time, "time", fields, data);

  useEffect(() => {
    if (data) {
      setCheck(data.open);
      setTime(data.time);
    }
  }, [data]);

  const handleCheck = (val) => (e) => {
    if (e.target.checked) {
      setCheck(val);
    } else {
      setCheck(null);
    }
    setTime([]);
  };

  const handleTime = (name, value) => {
    console.log(name, value);
    setFields({ ...fields, [name]: value });
    // setTime([...time].map((t,ind) => ind === i ? {...t,[name]: value} : {...i}))
  };

  const addTimeValues = () => {
    if (fields.start && fields.end) setTime([...time, fields]);
  };

  const deleteTime = (ind) => () => {
    setTime([...time].filter((t, i) => ind !== i));
  };

  const addFields = () => {
    setFields({ start: null, end: null });
  };

  const handleFields = () => {
    if (time.length > 0) setFields({});
    else setFields({ start: null, end: null });
  };

  useEffect(() => {
    handleFields();
  }, [time]);

  useEffect(() => {
    // if (time.length > 0 || check !== null)
    submitTime(time, check);
  }, [time, check]);

  return (
    <>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TableRow>
                <TableCell><p style={{ padding: "12px" }} className="pl2">{week}</p></TableCell>
                <TableCell>
                    <p><Checkbox checked={check === `close`} onChange={handleCheck(`close`)} /> Close</p>
                    <p><Checkbox checked={check === `open`} onChange={handleCheck(`open`)} /> All Day   </p>
                </TableCell>
                {!check ? <TableCell>

                    {time.map((val, i) => <p key={i}><div style={{ margin: '10px 0', display: 'flex' }} className="mv1"><RemoveCircle onClick={deleteTime(i)} style={{ color: '#FF0000', fontSize: '22px', flex: 0.4 }} />&nbsp;<span>{moment(val.start, "HH:mm").format('hh:mm A')}</span></div></p>)}

                    {Object?.keys(fields)?.length > 0 ? <div
                    ><TimePicker ampm={false} sx={{ ".MuiButtonBase-root": { padding: '0px !important', } }} className={`${fields.start && fields.end ? 'sfield_left' : 'sf_left'} wh_time`} onChange={(newValue) => handleTime('start', moment(newValue.$d, "HH:mm").format('HH:mm'))} placeholder="Start Time" value={moment(fields.start, "HH:mm").format('hh:mm')} /></div>
                        : <div className="" style={{ display: 'flex' }}><AddCircle onClick={addFields} style={{ flex: 0.55, fontSize: '22px', color: '#00C73D' }} />&nbsp;<span>Add Schedule</span>    </div>
                    }
                </TableCell>
                    : <TableCell align="center" sx={{ verticalAlign: 'middle!important' }}>{check === 'open' ? "24 hours" : check === 'close' && "Closed"}
                    </TableCell>}
                <TableCell>
                    {!check &&
                        <>
                            {time.map((val, i) => <p key={i}><div style={{ margin: '10px 0', display: 'flex' }} className="mv1"><RemoveCircle style={{ visibility: 'hidden', fontSize: '22px', flex: 0.4 }} />&nbsp;<span>{moment(val.end, "HH:mm").format('hh:mm A')}</span></div></p>)}
                           
                            {Object.keys(fields).length > 0 ? <div
                            ><TimePicker ampm={false} sx={{ ".MuiButtonBase-root": { padding: '0px !important' } }} className={`${fields.start && fields.end ? 'efield_left' : 'ef_left'} wh_time `} onChange={(newValue) => handleTime('end', moment(newValue.$d, "HH:mm").format('HH:mm'))} placeholder="End Time" value={moment(fields.end, "HH:mm").format('hh:mm')} /></div> :
                                <><AddCircle style={{ visibility: 'hidden' }} /> </>}
                        </>}
                </TableCell>
                <TableCell>
                    {time.map((val, i) => <p key={i}><div style={{ margin: "10px 0" }} className="mv1"><RemoveCircle style={{ visibility: 'hidden' }} /></div></p>)}
                    {!check && (fields.start && fields.end) && <><CheckCircleIcon onClick={addTimeValues} sx={{ color: '#0700C1' }} />&nbsp;&nbsp;<CloseIcon onClick={handleFields} sx={{ color: 'red' }} /></>}
                </TableCell>
            </TableRow>
        </LocalizationProvider> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TableRow>
          <TableCell>
            <p style={{ padding: "12px" }} className="pl2">
              {week}
            </p>
          </TableCell>
          <TableCell>
            <p>
              <Checkbox
                checked={check === "close"}
                onChange={handleCheck("close")}
              />{" "}
              Close
            </p>
            <p>
              <Checkbox
                checked={check === "open"}
                onChange={handleCheck("open")}
              />{" "}
              All Day
            </p>
          </TableCell>
          {!check ? (
            <TableCell>
              {time.map((val, i) => (
                <p key={i}>
                  <div
                    style={{ margin: "10px 0", display: "flex" }}
                    className="mv1"
                  >
                    <>
                      <RemoveCircle
                        onClick={deleteTime(i)}
                        style={{
                          color: "#FF0000",
                          fontSize: "18px",
                          flex: 0.4,
                        }}
                      />
                      &nbsp;
                      <span>
                        {moment(val.start, "HH:mm").format("hh:mm A")}
                      </span>
                    </>
                  </div>
                </p>
              ))}
              {Object.keys(fields).length > 0 ? (
                <div>
                  <TimePicker
                    ampm={false}
                    sx={{
                      ".MuiButtonBase-root": { padding: "0px !important" },
                    }}
                    className={`${fields.start && fields.end ? "sfield_left" : "sf_left"
                      } wh_time`}
                    onChange={(newValue) =>
                      handleTime(
                        "start",
                        moment(newValue.$d, "HH:mm").format("HH:mm")
                      )
                    }
                    placeholder="Start Time"
                    value={moment(fields.start, "HH:mm")}
                  />
                </div>
              ) : (
                <div className="" style={{ display: "flex"}}>
                  <>
                    <div onClick={addFields} style={{display:"flex"}} >
                      <AddCircle
                        // style={{ flex: 0.55, fontSize: "22px", color: "#00C73D" }}
                        style={{ fontSize: "22px", color: "#00C73D" }}
                      />
                      &nbsp;
                      <span>Add Schedule</span>
                    </div>
                  </>
                </div>
              )}
            </TableCell>
          ) : (
            <TableCell
              align="center"
              sx={{ verticalAlign: "middle!important" }}
            >
              {check === "open" ? "24 hours" : check === "close" && "Closed"}
            </TableCell>
          )}
          <TableCell>
            {!check && (
              <>
                {time.map((val, i) => (
                  <p key={i}>
                    <div
                      style={{ margin: "10px 0", display: "flex" }}
                      className="mv1"
                    >
                      <RemoveCircle
                        style={{
                          visibility: "hidden",
                          fontSize: "22px",
                          flex: 0.4,
                        }}
                      />
                      &nbsp;
                      <span>{moment(val.end, "HH:mm").format("hh:mm A")}</span>
                    </div>
                  </p>
                ))}
                {Object.keys(fields).length > 0 ? (
                  <div>
                    <TimePicker
                      ampm={false}
                      sx={{
                        ".MuiButtonBase-root": { padding: "0px !important" },
                      }}
                      className={`${fields.start && fields.end ? "efield_left" : "ef_left"
                        } wh_time`}
                      onChange={(newValue) =>
                        handleTime(
                          "end",
                          moment(newValue.$d, "HH:mm").format("HH:mm")
                        )
                      }
                      placeholder="End Time"
                      value={moment(fields.end, "HH:mm")}
                    />
                  </div>
                ) : (
                  <>
                    <AddCircle style={{ visibility: "hidden" }} />
                  </>
                )}
              </>
            )}
          </TableCell>
          <TableCell>
            {time.map((val, i) => (
              <p key={i}>
                <div style={{ margin: "10px 0" }} className="mv1">
                  <RemoveCircle style={{ visibility: "hidden" }} />
                </div>
              </p>
            ))}
            {!check && fields.start && fields.end && (
              <>
                <CheckCircleIcon
                  onClick={addTimeValues}
                  sx={{ color: "#0700C1" }}
                />
                &nbsp;&nbsp;
                <CloseIcon onClick={handleFields} sx={{ color: "red" }} />
              </>
            )}
          </TableCell>
        </TableRow>
      </LocalizationProvider>
    </>
  );
};

const WorkhoursPractitioner = (props) => {
  console.log(props, "workhours");
  const [hours, setHours] = useState([]);

  const [data, setData] = useState([]);
  const [id, setId] = useState("");

  const [tabs, setTabs] = useState(false);
  const [servicesList, setServicesList] = useState([]);

  const [healthCareService, setHealthCareServices] = React.useState([]);

  const [l2Data, setL2Data] = useState({});
  console.log(l2Data, "hey");

  console.log(hours, "hours", data);

  // let service = healthCareService?.[0]?.resource
  const params = useParams();

  const orgData = useSelector((state) => state.registration.userOrg);

  const location = useSelector((state) => state.registration.location);
  const l2id = useSelector((state) => state.registration.l2id);
  const locationL2Data = useSelector(
    (state) => state.registration.locationL2Data
  );

  console.log(location, "location");
  console.log(l2id, "l2ids");
  console.log(locationL2Data, "locationL2Data");

  let service = l2Data;

  console.log(service, "service");

  useEffect(() => {
    if (l2id) {
      D_API.get(`${orgData?.id}/Location/${l2id}`).then((res) => {
        console.log(res.data, "check plz");
        setL2Data(res.data);
      });
    }

  }, [l2id]);

  console.log(orgData, "orgggg");

  const dispatch = useDispatch();

  useEffect(() => {
    geServicesList();
  }, [location.id]);

  const geServicesList = () => {
    if (location?.id) {
      D_API.get(
        `${orgData?.id}/Location/partof/${location?.id}?count=50&page=1`
      )
        .then((res) => {
          setServicesList(res?.data?.entry?.map((val) => val?.resource) || []);
        })
        .catch((err) => {
          alert(
            err?.response?.data?.message || "Error.Please try after sometime"
          );
        });
    }
  };

  useEffect(() => {
    const whours = props.workHours;
    // console.log(whours, "+1")

    setId(whours?.id || "");
    console.log(223);

    if (whours?.length > 0) {
      let w = whours || setData([]);
      setHours([]);
      console.log(226);

      let open =
        [...w]?.[0]?.daysOfWeek?.map((k) => ({
          week: k,
          time: [],
          open: w[0].allDay ? "open" : "close",
        })) || [];
      let close =
        [...w]?.[1]?.daysOfWeek?.map((k) => ({
          week: k,
          time: [],
          open: w[1].allDay ? "open" : "close",
        })) || [];

      let time = [...w].splice(2, w.length - 1);
      let ti = [];

      // time.map(t => {
      //     if (ti.some(k => k.week === t.daysOfWeek[0])) {
      //         ti = ti.map(v => v.week === t.daysOfWeek[0] ? { ...v, time: [...v.time, { start: t.openingTime, end: t.closingTime }] } : { ...v })
      //     }
      //     else
      //         ti.push({ week: t.daysOfWeek[0], open: null, time: [{ start: t.openingTime, end: t.closingTime }] })
      // })
      time.map((t) => {
        if (ti.some((k) => k.week === t.daysOfWeek[0])) {
          ti = ti.map((v) =>
            v.week === t.daysOfWeek[0]
              ? {
                ...v,
                time: [
                  ...v.time,
                  { start: t.availableStartTime, end: t.availableEndTime },
                ],
              }
              : { ...v }
          );
        } else
          ti.push({
            week: t.daysOfWeek[0],
            open: null,
            time: [{ start: t.availableStartTime, end: t.availableEndTime }],
          });
      });

      let a = [];
      weeks.map((w) =>
        [...ti, ...open, ...close].map((v) =>
          w.slice(0, 3) === v.week ? a.push({ ...v, week: w }) : ""
        )
      );
      setData(a);
      console.log(a, "valuevalue");
      setHours(a.map((v) => ({ ...v, week: v.week.slice(0, 3) })));
      props.setGetTimings(a);
      props.dataGetting(a);
      // })
    }
  }, [props.workHours]);

  const handleData = (week) => (data, check) => {
    if (data.length > 0 || check !== null) {
      if (hours.some((hour) => hour.week.slice(0, 3) === week.slice(0, 3))) {
        setHours(
          [...hours].map((hour) =>
            hour.week.slice(0, 3) === week.slice(0, 3)
              ? { ...hour, time: data, open: check }
              : { ...hour }
          )
        );
      } else {
        setHours([
          ...hours,
          { time: data, open: check, week: week.slice(0, 3) },
        ]);
        console.log(211);
      }

      // console.log([...hours].filter(hour => hour.week === week ? {...hour,time: data,open: check} : {time: data,open: check,week: week}))
    } else
      setHours(hours.filter((h) => h.week.slice(0, 3) !== week.slice(0, 3)));
  };

  // useEffect(() => {

  //     // const whours = props.service
  //     const whours = service

  //     setId(whours?.id || '')
  //     console.log(223)

  //     if (whours?.hoursOfOperation?.length > 0) {
  //         let w = whours?.hoursOfOperation || setData([]); setHours([])
  //         console.log(226)

  //         let open = [...w]?.[0]?.daysOfWeek?.map(k => ({ week: k, time: [], open: w[0].allDay ? 'open' : 'close' })) || []
  //         let close = [...w]?.[1]?.daysOfWeek?.map(k => ({ week: k, time: [], open: w[1].allDay ? 'open' : 'close' })) || []

  //         let time = [...w].splice(2, w.length - 1)
  //         let ti = []

  //         // time.map(t => {
  //         //     if (ti.some(k => k.week === t.daysOfWeek[0])) {
  //         //         ti = ti.map(v => v.week === t.daysOfWeek[0] ? { ...v, time: [...v.time, { start: t.openingTime, end: t.closingTime }] } : { ...v })
  //         //     }
  //         //     else
  //         //         ti.push({ week: t.daysOfWeek[0], open: null, time: [{ start: t.openingTime, end: t.closingTime }] })
  //         // })
  //         time.map(t => {
  //             if (ti.some(k => k.week === t.daysOfWeek[0])) {
  //                 ti = ti.map(v => v.week === t.daysOfWeek[0] ? { ...v, time: [...v.time, { start: t.availableStartTime, end: t.availableEndTime }] } : { ...v })
  //             }
  //             else
  //                 ti.push({ week: t.daysOfWeek[0], open: null, time: [{ start: t.availableStartTime, end: t.availableEndTime }] })
  //         })

  //         let a = []
  //         weeks.map(w => [...ti, ...open, ...close].map(v => w.slice(0, 3) === v.week ? a.push({ ...v, week: w }) : ''))
  //         setData(a)
  //         console.log(a, "valuevalue")
  //         setHours(a.map(v => ({ ...v, week: v.week.slice(0, 3) })))
  //         props.setGetTimings(a)
  //         props.dataGetting(a)
  //         // })
  //     }

  // }, [service.id])

  useEffect(() => {
    props.setGetTimings(hours);
  }, [hours]);

  console.log(hours, "hourssss");

  const handleSubmit = async (required) => {
    let timings = [];

    console.log(hours);

    hours
      .filter((h) => h.time.length > 0)
      .map((v) =>
        v.time.map((t) =>
          timings.push({
            daysOfWeek: [v.week],
            openingTime: moment(t.start, "HH:mm").format("HH:mm:ss"),
            closingTime: moment(t.end, "HH:mm").format("HH:mm:ss"),
          })
        )
      );

    console.log(timings, "hi");

    let data = {
      // ...props.service,
      ...service,
      // appointmentRequired: required,
      hoursOfOperation: [
        {
          daysOfWeek: hours
            .filter((val) => val.open === "open")
            .map((h) => h.week),
          allDay: true,
        },
        {
          daysOfWeek: hours
            .filter((val) => val.open === "close")
            .map((h) => h.week),
          allDay: false,
        },
        ...timings,
      ],
    };
    console.log(data, "hi");
    // let dataUpdated = await updateHealthcare(data)
    // let dataUpdated = await updateLocation(data)

    props.handleSubmitAmenities(data);

    // let dataUpdated = await updateLocationAmenities(data)
    // // console.log(dataUpdated,"hii")

    // if (dataUpdated) {
    //   props.changeTab()
    // }
  };

  return (
    <div>
      {/* {tabs ? <ScheduleAvailabilityAmenities workHours={hours} appointmentRequired={props?.service?.appointmentRequired} close={props.close} submit={handleSubmit} /> : */}

      <ValidatorForm onSubmit={() => setTabs(true)}>
        <div
          style={{ height: "410px", overflow: "auto" }}
          className="tohideScrollbar"
        >
          <Table id="wh_table">
            <TableHead className="wh_head">
              <TableRow>
                {header.map((th, i) => (
                  <TableCell
                    sx={{ minWidth: { xs: "80px", sm: "80px", md: "0" } }}
                    key={i}
                  >
                    {th}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="wh_body">
              {weeks.map((week, i) => (
                <Fragment key={i}>
                  <WorkTable
                    week={week}
                    submitTime={handleData(week)}
                    data={data[i]}
                  />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
        {/* <FormDividrr /> */}

        {/* <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0 15px",
                        }}
                    >
                        <Button onClick={props.close} className="close-btn" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            sx={{ padding: "4px 20px !important" }}
                            name="save"
                            className="save-btn"
                            variant="contained"
                            disabled={hours.length !== 7}
                        >
                            Create
                        </Button>
                    </div> */}
        {/* <div className="mr2" style={{marginRight:"10px"}}>
          <Actions name="Next" disabled={hours.length !== 7} close={props.close} />
        </div> */}
      </ValidatorForm>
      {/* // } */}
    </div>
  );
};

export default WorkhoursPractitioner;
