import React, { useState, useEffect } from "react";
import DefaultHealthServiceIcon from "../../../../../../icons/Healthcare_Service.svg"
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Card, Divider, Grid } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { URI } from "../../../../../utility/uri";
import { DOCS_WITHOUT_TOKEN_WITH_TENANTS } from "../../../../../../services/APIService";
import { useSelector } from "react-redux";
import "../../../../../utility/style.css";

const ListOfPublichealthClinical = ({ orgInfo, updateTabVal,  setselectedClinicalItem }) => {
    const [listOfPublicHealthClinical, setListOfPublicHealthClinical] = useState([]);
    const [showMoreServices, setShowMoreServices] = useState(false);


    const orgData = useSelector(state => state.registration.userOrg)
    const location = useSelector(state => state.registration.location_withouttoken)

    useEffect(() => {
        if (orgData?.id) {
            getPublicClinicalList(orgData?.id);
        }

    }, [orgData?.id])

    const getPublicClinicalList = (orgId) => {
        if (orgId) {
            DOCS_WITHOUT_TOKEN_WITH_TENANTS.get(`${orgId}/${URI.getHealthCareServiceListWithOutToken}${orgId}`).then((res) => {

                if (res.data?.entry) {
                    setListOfPublicHealthClinical(res.data?.entry);
                }else{
                    setListOfPublicHealthClinical([]);  
                }
            }).catch((err) => {
                setListOfPublicHealthClinical([]);
                console.log(err)
            })
        }

    }

    const showLessCopyOfHealthService = () => {
        setShowMoreServices(false)
    }

    const showMoreCopyOfHealthService = () => {
        setShowMoreServices(true)
    }
    return (
        <div className='mt2'>
            <Card sx={{ padding: "10px", borderRadius: "10px", boxShadow: "0px 0px 1.6px #888", }} >
                <div>
                    <div className='flx  justify-between' >
                        <div style={{ color: "#3084BE", fontSize: "18px", fontWeight: 600 }} className='capitalize'>

                            Clinical Specialties
                        </div>
                        <div className='flx  justify-between'>
                            {/* {
                                (listOfHealthServices?.length >= 2 && isAdmin === "PERMIT") && <MenuIcon className="cursor" sx={{ width: "20px", height: "20px", color: "#750083", '.MuiSvgIcon-root': { width: "20px", height: "20px" } }} onClick={handleOpenRearrangeServiceDialog} />
                            }
                            {isAdmin === "PERMIT" && <AddCircleRoundedIcon className="cursor" sx={{ display: "flex", alignItem: "center", color: "#750083", width: "20px", height: "20px", '.MuiSvgIcon-root': { width: "20px", height: "20px" }, paddingLeft: "10px", paddingTop: "3px" }} onClick={handleAddServiceDeptDialog} />} */}
                        </div>
                    </div>
                </div>
                {
                    listOfPublicHealthClinical?.length > 0 &&

                    <div style={{ width: "100%" }}>
                        <Grid container spacing={2}>
                            {
                                listOfPublicHealthClinical?.length > 0 && listOfPublicHealthClinical?.slice(0, showMoreServices ? undefined : 4)?.map((val) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={6} >
                                            <Card sx={{ padding: "5px", borderRadius: "", boxShadow: "0px 0px 1.6px #888", height: { sm: "45px", md: "45px", lg: "45px" } }} className='mv1 cursor'
                                                onClick={() => {
                                                    updateTabVal("4");
                                                    setselectedClinicalItem(val?.resource)

                                                }}

                                            >
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    <div>
                                                        <Avatar variant="square" sx={{

                                                            width: '40px !important', height: '40px !important',

                                                        }} src={val?.resource?.photo?.url || DefaultHealthServiceIcon} />
                                                    </div>

                                                    <div style={{ height: "40px", width: '100%' }}>
                                                        <div className='fntMd capitalize ' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.name}</div>
                                                        <div className='fntXSm capitalize ' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.comment}</div>

                                                    </div>
                                                </div>
                                            </Card>
                                        </Grid>

                                    )
                                })

                            }
                        </Grid>
                    </div>
                }
                {/* <Divider /> */}
                {listOfPublicHealthClinical?.length > 4 &&
                    <div type="label" style={{ textAlign: 'center' }}>
                        {listOfPublicHealthClinical?.length > 4 && (
                            <>
                                <Divider />
                                {/* <div style={{ paddingTop: '5px', cursor: 'pointer', fontWeight: 'bold', background: hoverEffectforServices ? "#F4F2EE" : "" }} */}
                                <div style={{ paddingTop: '5px', cursor: 'pointer', fontWeight: 'bold' }}

                                // onMouseEnter={() => setHoverEffectforServices(true)}
                                // onMouseLeave={() => setHoverEffectforServices(false)}

                                >
                                    {showMoreServices ?
                                        <div className='flx-center justify-center' onClick={showLessCopyOfHealthService}>
                                            <div> Show Less</div>
                                            <KeyboardArrowUp style={{ marginTop: '3px' }} />

                                        </div>
                                        :
                                        <div className='flx-center justify-center' onClick={showMoreCopyOfHealthService} >
                                            <div> Show More </div>
                                            <KeyboardArrowDown style={{ marginTop: '3px' }} />
                                        </div>
                                    }
                                </div>
                            </>

                        )}
                    </div>
                }
                {/* <AddHealthServiceDept opn={openServiceDept} cls={handleAddServiceDeptDialog} /> */}
            </Card>
        </div>

    )
}
export default ListOfPublichealthClinical;

