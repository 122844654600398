
import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import UserService from "../../../services/UserService";
import { WarningAmber } from '@mui/icons-material';
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { socket } from "../../../socket";
import { headers } from "../../../redux/Actions/connectActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Close } from '@mui/icons-material';
import CssTextField from "../../Fields/CssTextField";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DeleteMyProfile = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [opnDialog, setOpnDialog] = useState(false);

    const history = useHistory();

    const handletoOpnDeleteConfirmDialog = () => {
        setOpnDialog(true)
    }

    const handletoClsDeleteConfirmDialog = () => {
        setOpnDialog(false)
    }

    const sessionLogout = () => {

        try {
            let decode = jwtDecode(UserService.getToken())
            axios.get(`https://api.docsteth.com/api/v1/session/logout/${decode?.sid}`, {
                headers: {
                    Authorization: `Bearer ${UserService.getToken()}`
                }
            }).then((res) => {

            }).catch((err) => console.log(err))
        } catch (error) {
            console.error('Error decoding token:', error);
        }

    }

    const handlesocketremove = (id) => {
        // console.log("ghfgh")
        socket?.disconnect(id)
    }


    const handleDeleteProfile = async (userID) => {

        // UserService.doLogout()
        // sessionStorage.removeItem('kctoken')
        // sessionStorage.removeItem('userId')
        // sessionStorage.removeItem("refresh_token")
        // sessionLogout();
        // window.location.href = '/home' 

        if (userID) {
            try {
                const response = await axios.put(
                    `${process.env.REACT_APP_API_URL}v1/updateUserAccountStatus/${userID}/DELETED`,
                    null,
                    headers()
                );
                UserService.doLogout()
                sessionStorage.removeItem('kctoken')
                sessionStorage.removeItem('userId')
                sessionStorage.removeItem("refresh_token")
                sessionLogout();
                window.location.href = '/home'
                // history.push("/home");
                // handlesocketremove(userID)

            } catch (err) {
                console.error('Error deleting profile:', err);
            }
        }
    }


    return (
        <div style={{ backgroundColor: "white", }}>
            <div className="cursor">
                <ArrowBackIcon onClick={() => history.goBack()} />
            </div>
            <ValidatorForm onSubmit={() => { }}>
                <Box
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100vh' }}
                >
                    <div>
                        <Typography variant='h6' sx={{ textAlign: 'center' }}>Delete Docsteth Profile</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box>

                                <CssTextField
                                    type={"text"}
                                    name="username"
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    placeholder={'User Name'}
                                    InputProps={{
                                        endAdornment: <Typography variant="body1">@docsteth</Typography>,
                                    }}

                                    sx={{ width: "320px" }}

                                />


                                <CssTextField
                                    name='password'
                                    type="password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    placeholder={'Password'}
                                    InputProps={{
                                        endAdornment: <Typography variant="body1"></Typography>,
                                    }}

                                    sx={{ width: "320px" }}
                                />


                                <Grid mt={2} container justifyContent="center">
                                    <Grid item>
                                        <Button
                                            type='submit'
                                            disabled={username && password ? false : true}
                                            sx={{ borderRadius: 3, mt: 2, width: "200px" }}
                                            variant="contained"
                                            color="primary"
                                            onClick={handletoOpnDeleteConfirmDialog}
                                            className="capitalize cursor"
                                        >
                                            {'Delete Profile'}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Box>
                        </Box>
                    </div>

                </Box>

            </ValidatorForm>
            <>
                <ValidatorForm onSubmit={() => { }}>
                    <Dialog
                        fullWidth
                        maxWidth={"md"}
                        open={opnDialog}
                        onClose={handletoClsDeleteConfirmDialog}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                        sx={{

                        }}
                    >
                        <DialogTitle sx={{
                            fontWeight: 500, p: '5px 15px', fontSize: '17px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                        }}>
                            <Box></Box>
                            <IconButton onClick={handletoClsDeleteConfirmDialog}><Close sx={{ color: 'red' }} /></IconButton>
                        </DialogTitle >
                        <DialogContent>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 500 }}><WarningAmber />
                                Are you sure you want to delete your account permanently</Typography>
                            <Typography sx={{ fontSize: 13, ml: 4 }}>Press "Delete account " to remove it permanently,or "Cancel" if you want to keep your benefits</Typography>
                        </DialogContent>
                        <DialogActions sx={{ m: 0, justifyContent: 'center' }}>
                            <Button sx={{ height: "30px", width: "200px", borderColor: "red", color: "red" }} onClick={() => handleDeleteProfile(UserService?.getUserId())}>
                                Delete Account
                            </Button>
                            <Button sx={{ height: "30px", width: "200px" }} onClick={handletoClsDeleteConfirmDialog}>
                                Cancel
                            </Button>

                        </DialogActions>
                    </Dialog>
                </ValidatorForm>
            </>

        </div>


    )
}
export default DeleteMyProfile;