import * as React from 'react';
import Register from './register'
import Cards from './Cards'
import Footer from './footer';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import CopyRights from './copyrights'
import Map from '../../images/group2.svg'
import Logo from './docstethLogo';
import '../SignUp/signup.css';
import Explore from '../../gifs/explore.gif';
import Peers from '../../gifs/connect.gif';
import Elib from '../../gifs/ePractice.gif';
import Medscope from '../../gifs/medscope.gif';
// import FadeIn from 'react-fade-in';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Explore from '../../images/explore3.svg';
// import Peers from '../../images/peers.svg';
// import Elib from '../../images/e-library.svg';
// import Medscope from '../../images/medscope.svg';
import greenBorder from '../../images/border-green.png';
import blueBorder from '../../images/border-blue.png';
import CarouselComponent1 from './CarouselComponent1';
import ExploreTopics from './ExploreTopics';
import iphoneVideo from '../../videos/Mobile-video.mp4';
import EPracticeCardsHeader from './EPracticeCardsHeader';
import Expertise from './Expertise';
import { useHistory } from 'react-router-dom';
import MedscopeSection from './MedscopeSection';
import Link from '../../icons/link_icon.svg'

export default function SignUp() {
  AOS.init({ duration: 2000 });

  let cardArray = [
    { title: 'Explore', border: greenBorder, icon: Explore, desc: 'Stay upto date with Trending Healthcare news feed, posts and share clinical Expertise' },
    { title: 'Connect', border: blueBorder, icon: Peers, desc: 'Connect and Network withPeers, follow organisations & topics' },
    { title: 'E-Practice', border: greenBorder, icon: Elib, desc: 'Clinical information tools for evidence based practice backed by Cochran library' },
    { title: 'Medscope', border: blueBorder, icon: Medscope, desc: 'Find Opportunities for your practice. Courses, Jobs' },

  ];

  const history = useHistory()

  const MidText = (props) => {
    return (
      <div style={{ paddingTop: '2rem', textAlign: 'center' }}>
        <Typography variant="h3" className='t1_text'>{props.t1}</Typography>
        <Typography variant='h5' className='t2_text'>{props.t2}</Typography>
      </div>
    )
  }

  return (

    <div className="sign-bg custom_reg">
      <Register />

      <div data-aos='fade-up' className='second-section'>

        <MidText t1="An Exclusive Platform For Doctors" t2="A healthcare eco-system powered by Social Networking" />

        <div data-aos='fade-up' className='card-container'>
          {cardArray.map((item) => {
            return <div className='card' key={item.title}>
              <div className='heightpercentage' style={{ height: "10%" }}>
                <h5>{item.title}</h5>
              </div>
              <div className='card-image heightpercentage' style={{ height: "40%" }}>
                <img className='image1' src={item.border} alt='image-border' />
                <img className='image2' src={item.icon} alt='icon' />
              </div>
              {/* <FadeIn delay={2000} transitionDuration={2000}>
                <div>
                  <p className='card-desc'>{item.desc}</p>
                </div>
              </FadeIn> */}
              <div data-aos='fade-up' className='heightpercentage' style={{ height: "30%" }} >
                <p className='card-desc'>{item.desc}</p>
              </div>
              <div className='heightpercentage' style={{ height: "20%" }}>
                {(item.title === 'Explore') ? <p className='more_card_text cursor'>more <img src={Link} style={{ paddingLeft: "10px" }} width="12px" height="12px"></img></p>
                  : (item.title === 'Connect') ? <p className='more_card_text cursor'>more <img src={Link} style={{ paddingLeft: "10px" }} width="12px" height="12px"></img></p>
                    : (item.title === 'E-Practice') ? <p className='more_card_text cursor' onClick={() => history.push('/elib/ebmkithomepage')}>more <img src={Link} style={{ paddingLeft: "10px" }} width="12px" height="12px"></img></p>
                      : (item.title === 'Medscope') ? <p className='more_card_text cursor'>more <img src={Link} style={{ paddingLeft: "10px" }} width="12px" height="12px"></img></p>
                        : null
                }
                {/* <p className='more_card_text' onClick={() => history.push('/elib/ebmkithomepage')} >more <img src={Link} style={{ paddingLeft: "10px" }} width="12px" height="12px"></img></p> */}
              </div>
            </div>

          })}
        </div>
        <div>
          <CarouselComponent1 />
        </div>
      </div>


      {/* <div style={{ position: 'relative' }}>
        <img src={../} alt='img' className="map" /> */}
      {/* <Logo header="f-logo img-position"/> */}
      {/* </div> */}
      {/* 
      <MidText t1='Zero tolerance to towards marketing messages & misleading Information' t2='We ensure HIPAA Compliant Security in Data sharing and International Laws for Protection of personal information' /> */}

      <div className='explore-topics' id="header_width">
        <ExploreTopics />
        <video className='iphone' autoPlay='autoPlay' loop muted>
          <source src={iphoneVideo} type="video/mp4" />
        </video>
      </div>

      <div className='e-practice-card-wrapper'>
        <div className='e-practice-header-wrapper'>
          <h2>E-Practice</h2>
          <p>Where knowledge meets practice, healthcare professionals can you E-practice to tools for the delivery of patient centric evidence based practice</p>
        </div>
        <div style={{ width: '100%', margin: '0 auto' }}>
          <EPracticeCardsHeader />
        </div>
      </div>

      <div>
        <div style={{ textAlign: "center" }}>
          <h2 className='one_profile'>One Profile that speak about you</h2>
          <h4 className='Interoperable_sentence'>Interoperable Practitioner profile, share and update your practice profile across multiple Network</h4>
        </div>
        <Expertise />
      </div>

      <div className='section6-medscope'>
        <div className='innersection1-medscope common-style-for-innersections'></div>
        <div className='innersection2-medscope common-style-for-innersections'></div>

        <div className='medscope-header'>
          <h2 >Medscope</h2>
          <p >Marketplace to find career and practice oppurtunities</p>
        </div>
        <div style={{ width: '90%', margin: '0 auto' }}>
          <MedscopeSection />
        </div>
      </div>

      <Footer />
      <CopyRights />
    </div>

  );
}
