const initialState = {
  userProfile: {},
  exp: [],
  active: sessionStorage.getItem("active") || 1,
  pageData: {},
  ChatAppOpen: false,
  notifications: [],
  getPost: {},
  getQuestionnaire: {},
  accomplishment: {},
  publicorgdata: {},
  findpeoplebypage: {},
  eventsbypageid: {},
  getalljobs: [],
  getallevents: [],
  getRefreshFeed: '',
  billingDetail: {},
  saveDraftArticle: [],

};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USER_PROFILE':
      return {
        ...state,
        userProfile: action.payload,
        exp: action.experience,
        pages: action.pages,
        events: action.events,
        user: action.userdata
      }
    case 'ACTIVE':
      return {
        ...state,
        active: action.payload
      }
    case 'PAGEDATA':
      return {
        ...state,
        pageData: action.payload
      }
    case 'CHATAPP':
      return {
        ...state,
        ChatAppOpen: action.payload
      }
    case 'NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload
      }
    case 'GETPOST':
      return {
        ...state,
        getPost: action.payload
      }
    case 'GET_QUESTIONNAIRE':
      return {
        ...state,
        getQuestionnaire: action.payload
      }
    case 'ACCOMPLISHMENT':
      return {
        ...state,
        accomplishment: action.payload
      }
    case 'ORGDATA':
      return {
        ...state,
        publicorgdata: action.payload
      }
    case 'PEOPLEPAGEDATA':
      return {
        ...state,
        findpeoplebypage: action.payload
      }
    case 'EVENTPAGEDATA':
      return {
        ...state,
        eventsbypageid: action.payload
      }
    case 'GETALLJOBS':
      return {
        ...state,
        getalljobs: action.payload

      }
    case 'GETALLEVENTS':
      return {
        ...state,
        getallevents: action.payload
      }
    case 'FEEDREFRESH':
      return {
        ...state,
        getRefreshFeed: action.payload
      }
    case 'BILLINGDETAILS':
      return {
        ...state,
        billingDetail: action.payload
      }

    case 'SAVE_DRAFT_ARTICLES':
      return {
        ...state,
        saveDraftArticle: action.payload
      }

    default:
      return state
  }
}
export default userReducer