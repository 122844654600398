import React,{useEffect,useState} from "react";
import { Add, Dehaze, Search } from "@mui/icons-material";
import { Box, Card, Grid, TextField, Typography, Avatar } from "@mui/material";
import { useSelector } from "react-redux";

import DefaultHealthServiceIcon from "../../../../../../icons/Healthcare_Service.svg"
import { URI } from "../../../../../utility/uri";
import { DOCS_WITHOUT_TOKEN_WITH_TENANTS } from "../../../../../../services/APIService";
import ParticularPublicOverviewClinical from "./particularPublicOverviewClinical";


const ParticularPublicClinicalInfo = ({ selectedItemOfClinical }) => {

    const [selectedItem, setSelectedItem] = useState(null);
    const [listOfHealthClinicals, setListOfHealthClinicals] = useState([]);
    const [filteredHealthClinicals, setFilteredHealthClinicals,] = useState([]);
    const [value, setValue] = useState(null)
    const orgData = useSelector(state => state.registration.userOrg)



    useEffect(() => {
        if (orgData?.id) {
            getPublicClinicalList(orgData?.id);
        }
    }, [orgData?.id])

    useEffect(() => {
        if (value?.length > 0) {
            const filteredList = listOfHealthClinicals.filter((dept) =>
                dept?.resource?.name?.toLowerCase().includes(value.toLowerCase()) ||
                dept?.resource?.alias?.[0]?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredHealthClinicals(filteredList);
        } else {
            setFilteredHealthClinicals(listOfHealthClinicals); // Reset to full list when input length is less than 3
        }
    }, [value, listOfHealthClinicals])


        useEffect(() => {
            if (selectedItemOfClinical) {
                setSelectedItem(selectedItemOfClinical)
            }
        }, [selectedItemOfClinical])
    

    const getPublicClinicalList = (orgId) => {
        if (orgId) {
            DOCS_WITHOUT_TOKEN_WITH_TENANTS.get(`${orgId}/${URI.getHealthCareServiceListWithOutToken}${orgId}`).then((res) => {
                if (res.data?.entry) {
                    setListOfHealthClinicals(res.data?.entry);
                } else {
                    setListOfHealthClinicals([]);
                }
            }).catch((err) => {
                setListOfHealthClinicals([]);

            })
        }

    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={3}
                    sx={{ height: "400px" }}>
                    <Card className="ph2" sx={{
                        marginTop: "5px",
                        borderRadius: "5px", height: { sm: "100%", md: "100%", lg: "100%" },
                        boxShadow: "0px 0px 1.6px #888",

                    }}>

                        <Box className="mt2" sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="p" sx={{ fontSize: "18px", fontWeight: 600, color: "#3084BE", }}>Healthcare Service</Typography>
                            {/* <Box sx={{ display: "flex" }}>
                                <Dehaze sx={{ fontSize: "18px", margin: "3px 8px 0 0" }} />
                                <Box sx={{ width: "18px", height: '18px', borderRadius: "2px", border: '1px solid #888', marginTop: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Add />
                                </Box>
                            </Box> */}
                        </Box>

                        <Box className="mt2" sx={{
                            width: "96%", padding: "0 4px", height: '36px', border: "1px solid #e0e0e0", borderRadius: "10px", display: "flex", alignItems: "center"
                        }}>
                            <Search sx={{ fontSize: "30px" }} />
                            <TextField
                                id="name"
                                sx={{
                                    width: "85%",
                                    margin: "0 0 0px 6px",
                                    "& .MuiInput-underline:before": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                        borderBottom: "none",
                                    }
                                }}
                                value={value || ""}
                                onChange={(event) => {
                                    console.log(event.target.value, "900")
                                    setValue(event.target.value)
                                }}
                                placeholder="Search Healthcare Service"
                                type="text"
                                variant="standard"
                            />
                        </Box>
                        {console.log(filteredHealthClinicals, "filteredHealthServices")}
                        <Box sx={{
                            overflowY: 'scroll',
                            mt: 1,
                            height: { xs: '100vh', sm: '100vh', md: '38vh' },
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            }
                        }}>
                            {
                                filteredHealthClinicals?.length > 0 && filteredHealthClinicals?.map((val) => {

                                    return (
                                        // <Grid item xs={12} sm={12} md={12} lg={12} >
                                        <Card sx={{
                                            width: "94%", marginLeft: "2px",
                                            padding: "5px 5px 0 6px", borderRadius: "", height: { sm: "60px", md: "60px", lg: "60px" },
                                            backgroundColor: selectedItem?.id === val?.resource?.id ? "lightblue" : "none",
                                            boxShadow: "0px 0px 1.6px #888", display: "flex", alignItems: "center"
                                        }} className='mt1 cursor '

                                            onClick={() => {
                                                setSelectedItem(val.resource)
                                            }}

                                        >
                                            <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "10px" }}>
                                                <div>
                                                    <Avatar variant="square" sx={{

                                                        width: '40px !important', height: '40px !important',

                                                    }} src={val?.resource?.photo?.url || DefaultHealthServiceIcon} />
                                                </div>

                                                <div style={{ height: "40px", width: '100%' }}>
                                                    <div className='fntMd capitalize ' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.name}</div>
                                                    <div className='fntXSm capitalize ' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.comment}</div>

                                                </div>
                                            </div>
                                        </Card>
                                        // </Grid>
                                    )
                                }
                                )
                            }
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={9}>

                {
                        selectedItem &&
                        (
                            <ParticularPublicOverviewClinical clinicalInfo={selectedItem} />
                        )
                    }
             
                </Grid>
            </Grid>
        </div>
    )
}
export default ParticularPublicClinicalInfo;