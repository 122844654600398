import React, { useState, useEffect } from "react";
import { Card, Grid, Stack, Typography } from '@mui/material';
import UserService from "../../../services/UserService";
import Feed from "../../Molecule/Feed/Feed";
import Vollmond from '../../../icons/vollmond.svg';
import Downarrow from '../../../icons/downarrow.svg';
import URL from "../../apiurl";
import axios from "axios";
import { headers } from "../../../redux/Actions/regActions";
import { useSelector, useDispatch } from "react-redux";

const ProfileTimeline = () => {
    const [view, setView] = useState(false)
    const [selectedTimelineType, setSelectedTimelineType] = useState("All");
    const [articleDetails, setArticleDetails] = useState([]);

    const saveDraft = useSelector((state) => state.userProfile.saveDraftArticle)

    useEffect(() => {
        setArticleDetails(saveDraft)
    }, [saveDraft])

    const dispatch = useDispatch();

    const getDraftArticles = () => {
        axios.get(`${URL.getdraftArticles}${UserService.getUserId()}`, headers()).then((res) => {
            let obj = {
                type: "SAVE_DRAFT_ARTICLES",
                payload: res.data
            }
            console.log(res.data, "sigfg");
            dispatch(obj)

        }).catch((err) => {
            let obj = {
                type: "SAVE_DRAFT_ARTICLES",
                payload: []
            }
            dispatch(obj)
            console.log(err);
        })
    }
    useEffect(() => {

        if (selectedTimelineType == "ARTICLE") {
            console.log("hello vinay")
            getDraftArticles();
        } else {
            // setArticleDetails([]);
        }
    }, [selectedTimelineType])
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} className="margin_myprofile">
                    {/* <div className='border_img mt-10' style={{ boxShadow: "0px 0px 1.6px #888", borderRadius: "10px" }}>
                        <div className='recent_activity dflex_al_jc' style={{ padding: "15px" }}>
                            <h2 style={{ fontSize: "18px", fontWeight: "400", color: "#47AEE1" }}>Recent Activity</h2>
                        </div>
                        <div>
                            <Typography variant='h6' style={{ textAlign: "center", color: "#36454F", fontSize: "18px" }}>No Data</Typography>
                        </div> */}
                    {/* <div style={{ padding: "10px 20px" }}>
                            <div className='dflex_al_jc'>
                                <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                    <p>Registered Events</p>
                                </div>
                                <div>
                                    <img width="10px" height="10px" src={Downarrow}></img>
                                </div>
                            </div>
                            <div className='mt-10' style={{ display: "flex" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seeAll</h4>
                            </div>
                        </div> */}
                    {/* <div style={{ padding: "10px 20px" }}>
                            <div className='dflex_al_jc'>
                                <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                    <p># Topics</p>
                                </div>
                                <div>
                                    <img width="10px" height="10px" src={Downarrow}></img>
                                </div>
                            </div>
                            <div className='mt-10' style={{ display: "flex" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seemore</h4>
                            </div>
                        </div> */}
                    {/* <div style={{ padding: "10px 20px" }}>
                            <div className='dflex_al_jc'>
                                <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                    <p>Pages</p>
                                </div>
                                <div>
                                    <img width="10px" height="10px" src={Downarrow}></img>
                                </div>
                            </div>
                            <div className='mt-10' style={{ display: "flex" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seemore</h4>
                            </div>
                        </div> */}
                    {/* <div style={{ padding: "10px 20px" }}>
                            <div className='dflex_al_jc'>
                                <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                    <p>People</p>
                                </div>
                                <div>
                                    <img width="10px" height="10px" src={Downarrow}></img>
                                </div>
                            </div>
                            <div className='mt-10' style={{ display: "flex" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seeAll</h4>
                            </div>
                        </div> */}
                    {/* </div> */}
                    <Card sx={{ height: {  md: '100vh' }, borderRadius: "10px", boxShadow: "0px 0px 1.6px #888", }} className='mt1'>
                        <div className='p1' >
                            <Stack spacing={1}>
                                <div className={selectedTimelineType == "All" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("All")}><span className='pl1 fntSm14 capitalize '>All</span></div>
                                <div className={selectedTimelineType == "ARTICLE" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("ARTICLE")} ><span className='pl1 fntSm14  capitalize '>Articles</span></div>
                                <div className={selectedTimelineType == "EVENT" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("EVENT")} ><span className='pl1 fntSm14  capitalize'>Events</span></div>
                                <div className={selectedTimelineType == "POLL" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("POLL")} ><span className='pl1 fntSm14  capitalize'>Poll</span></div>
                                <div className={selectedTimelineType == "IMAGE" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("IMAGE")} ><span className='pl1 fntSm14  capitalize'>Images</span></div>
                                <div className={selectedTimelineType == "VIDEO" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("VIDEO")} ><span className='pl1 fntSm14  capitalize'>Videos</span></div>
                                <div className={selectedTimelineType == "TEXT" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("TEXT")} ><span className='pl1 fntSm14  capitalize'>Text</span></div>
                                <div className={selectedTimelineType == "CELEBRATE" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("CELEBRATE")} ><span className='pl1 fntSm14  capitalize'>Celebrations</span></div>

                            </Stack>
                        </div>
                    </Card>

                </Grid>
                <Grid item xs={12} md={8}>
                    <Feed view={true} selectedPostType={selectedTimelineType} articleInfo={articleDetails}></Feed>
                </Grid>
            </Grid>

        </>
    )
}

export default ProfileTimeline;