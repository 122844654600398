import axios from "axios";
import URL from "../../Components/apiurl";
import UserService from "../../services/UserService";
import HttpService from "../../services/HttpService";
import { getLoc } from "../../Components/Molecule/Feed/Feed";
import { headers, payloadData } from "./connectActions";
import {
  deleteEmptyKeys,
  deleteKeyAddre,
  deleteKeys,
  filter_data,
  updateSteps,
  updateStepsData,
} from "../../Components/common/functions/functions";
import { useEffect } from "react";
import API, {
  D_API,
  D_API_FHIR,
  DOCS_WITHOUT_TOKEN,
} from "../../services/APIService";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { getPractitionerFromFhir } from "./regActions";

export const userProfile = (data, exp, pages, events, userdata) => {
  // console.log(data, exp)
  return {
    type: "FETCH_USER_PROFILE",
    payload: data,
    experience: exp,
    pages: pages,
    events: events,
    userdata: userdata,
    method: HttpService.HttpMethods.POST,
    // practitionar:practitionar
  };
};

export const getPageData = (data) => {
  return {
    type: "PAGEDATA",
    payload: data,
  };
};

export const fetchProfile = (id) => {
  sessionStorage?.setItem("userId", UserService.getUserId());
  sessionStorage?.setItem("kctoken", UserService.getToken());
  sessionStorage?.setItem("refresh_token", UserService.getRefreshToken());
  localStorage?.setItem("client", UserService?.getClientName());
  // console.log(UserService.getToken())
  return (dispatch) => {
    axios
      .get(`${URL.findUser}/${UserService.getUserId()}`, headers())
      .then((response) => response.data)
      .then((data) => {
        let exp = data.data.experience
          ? data.data.experience.length > 0
            ? data.data.experience
            : {}
          : {};
        dispatch(
          userProfile(
            data.data.user,
            exp,
            data.data.pages,
            data.data.events,
            data.data
          )
        );
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
};

export const updateProfile = (
  user,
  contact,
  addressList,
  qualification,
  licence,
  UsersDatas,
  hprIdDetails
) => {
  // console.log(user, "update", qualification, licence,identi,hprIdDetails)
  console.log("update", hprIdDetails);
  console.log(UsersDatas, "9339")
  console.log(qualification, "zero", licence)
  return async (dispatch, getState) => {
    let data = {
      "about": user?.about,
      "captionAndTaglines": user?.caption,
      "fullName": user?.text,
      "id": user?.id,
      "keyclockId": user?.keyclockId,
      "coverPic": user?.coverPic,
      "metadata": await getMetaData(getState().userProfile.userProfile?.metadata),
      "practioner": {
        "resourceType": "Practitioner",
        "id": "example",
        "text": {
          "status": "generated",
          "div": `<div xmlns=http://www.w3.org/1999/xhtml>\n      <p>${user?.text}</p>\n    </div>`
        },
        "name": [
          {
            "use": "official",
            "text": user?.text,
            "family": user?.family,
            "given": [
              user?.firstName, user?.middleName
            ],
            "prefix": [
              user?.prefix
            ],
            "suffix": [...user?.suffix]
          }
        ],
        "telecom": contact,
        "gender": user?.gender,
        active: UsersDatas?.resource?.active && UsersDatas?.resource?.active != null && UsersDatas?.resource?.active != undefined && UsersDatas?.resource?.active != "" ? UsersDatas?.resource?.active : false,
        // active: user.active,
        ...(user?.languages?.length > 0 && {
          "communication": user?.languages?.map(e => ({
            "language": {
              "coding": [{
                "system": "urn:ietf:bcp:47",
                "code": e?.code,
                "display": e?.display
              }]
            }
            // "preferred": false

          }))
        }),

        identifier:

          hprIdDetails ?
            [

              {
                "system": "https://dev.docsteth.com",
                "value": `${UserService.getUserId()}`
              },
              {
                "system": "https://dev.docsteth.com",
                "value": `${UserService.getUsername()}`
              },
              {
                "system": "https://healthid.ndhm.gov.in/",
                "value": `${hprIdDetails}`
              },
              ...(user.caption ? [{ "system": "https://profiletagline/", value: user.caption }] : [])
            ]
            : [
              {
                "system": "https://dev.docsteth.com",
                "value": `${UserService.getUserId()}`
              },
              {
                "system": "https://dev.docsteth.com",
                "value": `${UserService.getUsername()}`
              },
              ...(user.caption ? [{ "system": "https://profiletagline/", value: user.caption }] : [])

            ],
        // ...(identi?.length > 0
        //     ? [

        //         {
        //             "system": "https://dev.docsteth.com",
        //             "value": `${UserService.getUserId()}`
        //         },
        //         {
        //             "system": "https://dev.docsteth.com",
        //             "value": `${UserService.getUsername()}`
        //         },
        //         {
        //             "system": "https://healthid.ndhm.gov.in/",
        //             "value": `${identi}`
        //         },
        //     ]
        //     : [
        //         {
        //             "system": "https://dev.docsteth.com",
        //             "value": `${UserService.getUserId()}`
        //         },
        //         {
        //             "system": "https://dev.docsteth.com",
        //             "value": `${UserService.getUsername()}`
        //         },

        //     ]
        // )

        "birthDate": user?.birthDate,
        "photo": [
          {
            "url": user?.photo,
            "show": user?.show
          }
        ],
        "address": addressList,

        // qualification: [...qualification?.map(q => {
        //     if (!q?.course?.code) return null; // Skip the qualification if code is not available
        //     return deleteEmptyKeys({
        //         identifier: [{
        //             value: q?.identifier,
        //             type: {
        //                 "coding": [{
        //                     code: "CER",
        //                     system: "http://hl7.org/fhir/ValueSet/identifier-type",
        //                     display: "Certificate"
        //                 }]
        //             }
        //         }],
        //         code: {
        //             // text: q.course,
        //             text: q?.degree?.display,
        //             coding: [
        //                 {
        //                     code: q?.course?.code,
        //                     system: q?.degree?.system,
        //                     display: q?.course?.display
        //                 }
        //             ]
        //         },
        //         issuer: {
        //             display: q?.issuer?.name,
        //             reference: `Organization/${q?.issuer?.id?.trim()}`
        //         },

        //     });
        // }), ...licence.map(li => (
        //     deleteEmptyKeys({
        //         identifier: [{
        //             value: li?.regNo,

        //             type: {
        //                 "coding": [{
        //                     code: "LN",
        //                     system: "http://hl7.org/fhir/ValueSet/identifier-type",
        //                     display: "Licence Number"
        //                 }]
        //             }
        //         }],
        //         code: {
        //             coding: [
        //                 {
        //                     code: 'LN',
        //                     system: "http://terminology.hl7.org/CodeSystem/v2-0360/2.7",
        //                 }
        //             ]
        //         },
        //         issuer: {
        //             display: li?.council
        //         },
        //         period: {
        //             start: li?.date,
        //             end: li?.dueDate,
        //         }
        //     }
        //     )))],

        // new code
        // qualification: [

        //     ...(qualification.length > 0 ? qualification : []),
        //     ...(licence.length > 0 ? licence : []),
        // ]

        // old code
        qualification: [
          ...qualification?.map(q => (
            {
              identifier: [{
                value: q?.identifier,
                type: {
                  coding: [{
                    code: "CER",
                    system: "http://hl7.org/fhir/ValueSet/identifier-type",
                    display: "Certificate"
                  }]

                }
              }],
              code: {
                text: q?.degree?.display,
                coding: [
                  {
                    code: q?.course?.code,
                    system: q?.degree?.system,
                    display: q?.course?.display
                  }
                ]
              },
              // issuer: q.issuer?.name && q.issuer?.id ? {
              //     display: q?.issuer?.name,
              //     reference: `Organization/${q?.issuer?.id?.trim()}`
              // } :
              //     q.issuer ? {
              //         display: q?.issuer,

              //     } : ""
              // ,
              issuer: {
                display: q?.issuer?.name || q?.issuer,
                reference: `Organization/${q?.issuer?.id?.trim()}`
              },
              period: {
                end: q?.dateIss,
              }

            }
          )), ...licence?.map(li => (
            {
              identifier: [{
                value: li?.regNo,
                type: {
                  coding: [{
                    code: "LN",
                    system: "http://hl7.org/fhir/ValueSet/identifier-type",
                    display: "Licence Number"
                  }]

                }
              }],
              code: {
                coding: [
                  {
                    code: 'LN',
                    system: "http://terminology.hl7.org/CodeSystem/v2-0360/2.7",
                  }
                ]
              },
              issuer: {
                display: li?.council
              },
              period: {
                start: li?.date,
                end: li?.dueDate,
              }
            }
          ))],
      }
    }

    let dataAcc = {
      "resourceType": "Practitioner",

      "name": [
        {
          "use": "official",
          "text": user?.text,
          "family": user?.family,
          "given": [
            user?.firstName, user?.middleName
          ].filter((val) => Boolean(val)),
          "prefix": [
            user?.prefix
          ].filter((val) => Boolean(val)),
          "suffix": [...user?.suffix].filter((val) => Boolean(val))
        }
      ],

      ...(contact?.length > 0 && {
        telecom: contact.map(each => (
          each.system === "phone" ? {
            "system": "phone",
            "use": each?.use,
            "value": each?.value
          } : {
            "system": "email",
            "use": each?.use,
            "value": each?.value
          }
        ))
      }),

      "gender": user?.gender,
      ...(user?.languages?.length > 0 && user.languages.some(e => e.display) && {
        "communication": user?.languages?.map(e => ({
          "coding": [{
            "system": "urn:ietf:bcp:47",
            "code": e?.code,
            "display": e?.display
          }]
        }))
      }),
      active: UsersDatas?.resource?.active && UsersDatas?.resource?.active != null && UsersDatas?.resource?.active != undefined && UsersDatas?.resource?.active != "" ? UsersDatas?.resource?.active : false,
      // active: user.active,
      // identifier: [
      //     {
      //         "system": "https://dev.docsteth.com",
      //         "value": `${UserService.getUserId()}`
      //     },
      //     {
      //         "system": "https://dev.docsteth.com",
      //         "value": "harini@docsteth"
      //     }
      // ],
      // identifier: [
      //     ...(identi?.length > 0
      //         ? [
      //             ...identi
      //         ]
      //         : [
      //             {
      //                 "system": "https://dev.docsteth.com",
      //                 "value": `${UserService.getUserId()}`
      //             },
      //             {
      //                 "system": "https://dev.docsteth.com",
      //                 "value": `${UserService.getUsername()}`
      //             },

      //         ]
      //     )
      // ],
      identifier:
        hprIdDetails ?
          [

            {
              "system": "https://dev.docsteth.com",
              "value": `${UserService.getUserId()}`
            },
            {
              "system": "https://dev.docsteth.com",
              "value": `${UserService.getUsername()}`
            },
            {
              "system": "https://healthid.ndhm.gov.in/",
              "value": `${hprIdDetails}`
            },
            ...(user.caption ? [{ "system": "https://profiletagline/", value: user.caption }] : [])

          ]
          : [
            {
              "system": "https://dev.docsteth.com",
              "value": `${UserService.getUserId()}`
            },
            {
              "system": "https://dev.docsteth.com",
              "value": `${UserService.getUsername()}`
            },
            ...(user.caption ? [{ "system": "https://profiletagline/", value: user.caption }] : [])

          ],
      // ...(identi?.length > 0
      //     ? [

      //         {
      //             "system": "https://dev.docsteth.com",
      //             "value": `${UserService.getUserId()}`
      //         },
      //         {
      //             "system": "https://dev.docsteth.com",
      //             "value": `${UserService.getUsername()}`
      //         },
      //         {
      //             "system": "https://healthid.ndhm.gov.in/",
      //             "value": `${identi}`
      //         },

      //         ...(user.caption ? [{ "system": "https://profiletagline/", value: user.caption }] : [])

      //     ]
      //     : [
      //         {
      //             "system": "https://dev.docsteth.com",
      //             "value": `${UserService.getUserId()}`
      //         },
      //         {
      //             "system": "https://dev.docsteth.com",
      //             "value": `${UserService.getUsername()}`
      //         },
      //         ...(user.caption ? [{ "system": "https://profiletagline/", value: user.caption }] : [])

      //     ]
      // )

      // "birthDate": "2024-05-01",
      "birthDate": dayjs(user?.birthDate).format('YYYY-MM-DD'),
      ...(user?.photo && { "photo": [{ "url": user?.photo }] }),

      // "photo": [
      //     {
      //         "url": user?.photo,
      //     }
      // ],

      // ...(Object.values(addressList).length > 0 && {
      //     "address": [Object.keys(deleteKeys({
      //         line: [addressList.line1, addressList.line2],
      //         city: addressList.city,
      //         country: addressList.city,
      //         postalCode: addressList.postalCode,
      //         state: addressList.state
      //     })).length > 0 ? deleteKeys({
      //         line: [addressList.line1, addressList.line2],
      //         city: addressList.city,
      //         country: addressList.city,
      //         postalCode: addressList.postalCode,
      //         state: addressList.state
      //     }) : undefined]?.filter((val) => val != undefined)?.length == 0,
      // }
      // ),

      ...(addressList && {
        "address": [deleteKeys({
          city: addressList?.[0]?.city ? addressList?.[0]?.city : "",
          country: addressList?.[0]?.country ? addressList?.[0]?.country : "",
          line: [addressList?.[0]?.line?.[0] && addressList?.[0]?.line?.[0], addressList?.[0]?.line?.[1] && addressList?.[0]?.line?.[1]].filter((val) => Boolean(val)),
          state: addressList?.[0]?.state ? addressList?.[0]?.state : "",
          postalCode: addressList?.[0]?.postalCode ? addressList?.[0]?.postalCode : ''
        })]
      }),
      // "address": addressList.map(item => deleteKeyAddre(item)),
      // "address": addressList,

      // ...(user?.communication.length > 0 && {
      //     communication: user?.communication?.map((e) => {
      //         return {
      //             coding: [{ ...e }]
      //         }
      //     })
      // }
      // ),

      // qualification: [...qualification?.map(q => {
      //     if (!q?.course?.code) return null; // Skip the qualification if code is not available
      //     return {
      //         identifier: [{
      //             value: q?.identifier,
      //             type: {
      //                 "coding": [{
      //                     code: "CER",
      //                     system: "http://hl7.org/fhir/ValueSet/identifier-type",
      //                     display: "Certificate"
      //                 }]
      //             }
      //         }],
      //         code: {
      //             // text: q.course,
      //             text: q?.degree?.display,
      //             coding: [
      //                 {
      //                     code: q?.course?.code,
      //                     system: q?.degree?.system,
      //                     display: q?.course?.display
      //                 }
      //             ]
      //         },
      //         issuer: {
      //             display: q?.issuer?.name,
      //             reference: `Organization/${q?.issuer?.id?.trim()}`
      //         },

      //     };
      // }), ...licence.map(li => (
      //     {
      //         identifier: [{
      //             value: li?.regNo,

      //             type: {
      //                 "coding": [{
      //                     code: "LN",
      //                     system: "http://hl7.org/fhir/ValueSet/identifier-type",
      //                     display: "Licence Number"
      //                 }]
      //             }
      //         }],
      //         code: {
      //             coding: [
      //                 {
      //                     code: 'LN',
      //                     system: "http://terminology.hl7.org/CodeSystem/v2-0360/2.7",
      //                 }
      //             ]
      //         },
      //         issuer: {
      //             display: li?.council
      //         },
      //         period: {
      //             start: li?.date,
      //             end: li?.dueDate,
      //         }
      //     }
      // ))],

      // new code
      // qualification: [
      //     ...(qualification.length > 0 ? qualification : []),
      //     ...(licence.length > 0 ? licence : []),
      // ]

      // old code
      qualification: [...qualification?.map(q => (
        {
          identifier: [{
            value: q?.identifier,
            type: {
              "coding": [{
                code: "CER",
                system: "http://hl7.org/fhir/ValueSet/identifier-type",
                display: "Certificate"
              }]
            }
          }],
          code: {
            // text: q.course,
            text: q?.degree?.display,
            coding: [
              {
                code: q?.course?.code,
                system: q?.degree?.system,
                display: q?.course?.display
              }
            ]
          },

          // issuer: q.issuer?.name && q.issuer?.id ? {
          //     display: q?.issuer?.name,
          //     reference: `Organization/${q?.issuer?.id?.trim()}`
          // } :
          //     q.issuer ? {
          //         display: q?.issuer,

          //     } : ""
          // ,
          issuer: {
            display: q?.issuer?.name,
            reference: `Organization/${q?.issuer?.id?.trim()}`
          },
          period: {
            end: q?.dateIss,
          }

        }
      )), ...licence.map(li => (
        {
          identifier: [{
            value: li?.regNo,
            type: {
              "coding": [{
                code: "LN",
                system: "http://hl7.org/fhir/ValueSet/identifier-type",
                display: "Licence Number"
              }]
            }
          }],
          code: {
            coding: [
              {
                code: 'LN',
                system: "http://terminology.hl7.org/CodeSystem/v2-0360/2.7",
              }
            ]
          },
          issuer: {
            display: li?.council
          },
          period: {
            start: li?.date,
            end: li?.dueDate,
          }
        }
      ))],

    }
    // console.log(data, "jkdjskjsksjk", deleteEmptyKeys(dataAcc));
    // console.log(dataAcc, "data")

    let dataAccc = deleteEmptyKeys(dataAcc)
    // console.log(data, "check", dataAcc);

    // axios.put(`${URL.updateUser}/${user?.id}`, data, headers())
    //     .then(response => {
    //         console.log(response)
    //         dispatch(fetchProfile(user?.id))
    //     })
    //     .catch(error => { });

    // DOCS_WITHOUT_TOKEN.get(`Practitioner/identifier/new/dev.docsteth.com/${UserService.getUserId()}`)
    //     .then(res => {
    //         if (res.status === 200 && res?.data?.total !== 0 && res?.data?.entry?.[0]) {
    //             D_API_FHIR.put(`Practitioner/${res?.data?.entry?.[0]?.resource?.id}`, { ...dataAccc, id: res?.data?.entry?.[0]?.resource?.id }, headers())
    //                 .then(response => {
    //                     console.log(response)
    //                 })
    //                 .catch(error => { })
    //         } else if (res.status == 200 && res?.data?.total === 0) {
    //             D_API_FHIR.post(`Practitioner`, dataAccc, headers())
    //                 .then(response => {
    //                     console.log(response)
    //                 })
    //                 .catch(error => { })
    //         }
    //     })
    //     .catch(err => console.log(err))

    let tokenInfo = jwtDecode(sessionStorage.getItem("kctoken"));
    // consoe.log("kctoken")
    if (tokenInfo?.ibmId) {
      D_API.put(`Practitioner/${tokenInfo?.ibmId}`, { ...dataAccc, id: tokenInfo?.ibmId }, headers())
        .then(response => {
          console.log(response)
          dispatch(getPractitionerFromFhir())

        })
        .catch(error => { })

    }

  }
};

export const handleActiveValue = (value) => {
  sessionStorage.setItem("active", value);
  let val = sessionStorage.getItem("active");
  // console.log(val)
  return {
    type: "ACTIVE",
    payload: val,
    // practitionar:practitionar
  };
};

export const getPageById = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL.getPageById}/${id}`, headers())
      .then((res) => {
        // console.log(res)
        dispatch(getPageData(res.data.data[0].page));
      })
      .catch((err) => { });
  };
};

export const getChatAppOpen = (val) => {
  // console.log(val)
  return {
    type: "CHATAPP",
    payload: val,
  };
};

export const getNotifications = () => {
  return (dispatch) => {
    axios
      .get(`${URL.getNotifications}/${UserService.getUserId()}`, headers())
      .then((res) => {
        // console.log(res)
        dispatch({
          type: "NOTIFICATIONS",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "NOTIFICATIONS",
          payload: [],
        });
      });
  };
};

export const getPost = (id) => {
  return async (dispatch) => {
    let postdata = await getPostById(id);
    dispatch(payloadData("GETPOST", postdata));
  };
};

export const getPostById = async (id) => {
  return await axios
    .get(`${URL.postById}/${id}`, headers())
    .then((res) => {
      console.log(res, "testtttt1")
      return res.data;
    })
    .catch((err) => {
      return { error: true };
    });
};

export const getMetaData = async (state) => {
  return {
    ...state,
    dateTime: new Date().toISOString(),
    location: await getLoc(),
    author: UserService.getUserId(),
    versionId: 0,
    securityCodes: null,
  };
};

export const getQuestionnaire = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL.getQuestionnaireById}/${id}`, headers())
      .then((res) => {
        dispatch({
          type: "GET_QUESTIONNAIRE",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_QUESTIONNAIRE",
          payload: {},
        });
      });
  };
};

export const accomplishment = () => {
  return (dispatch) => {
    axios
      .get(`${URL.accomplishments}/${UserService.getUserId()}`, headers())
      .then((res) => {
        // console.log(res.data)
        dispatch({
          type: "ACCOMPLISHMENT",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "ACCOMPLISHMENT",
          payload: {},
        });
      });
  };
};

export const publicorgdata = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL.getpagewithouttoken}/${id}`)
      .then((res) => {
        // console.log(res.data)
        dispatch({
          type: `ORGDATA`,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "ORGDATA",
          payload: {},
        });
      });
  };
};

export const findpeoplebypage = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL.findPeopleByPageId}/${id}`, headers())
      .then((res) => {
        // console.log(res.data)
        dispatch({
          type: "PEOPLEPAGEDATA",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PEOPLEPAGEDATA",
          payload: {},
        });
      });
  };
};

export const eventsbypageid = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL.Eventsbypageid}/${id}`, headers())
      .then((res) => {
        // console.log(res.data)
        dispatch({
          type: "EVENTPAGEDATA",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "EVENTPAGEDATA",
          payload: {},
        });
      });
  };
};
export const getalljobs = () => {
  return (dispatch) => {
    axios
      .get(`${URL.getlistofjobs}`, headers())
      .then((res) => {
        // console.log(res)
        dispatch({
          type: "GETALLJOBS",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "GETALLJOBS",
          payload: {},
        });
      });
  };
};

export const getallevents = () => {
  return (dispatch) => {
    axios
      .get(`${URL.getlistofevents}`, headers())
      .then((res) => {
        dispatch({
          type: "GETALLEVENTS",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "GETALLEVENTS",
          payload: {},
        });
      });
  };
};

export const doRefresh = (value) => {
  return (dispatch) => {
    dispatch({
      type: "FEEDREFRESH",
      payload: value,
    });
  };
};
