
import React, { useState, useEffect } from "react";
import { Card, Grid, Box, Typography, TextField, Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { URI } from "../../../../../utility/uri";
import { DOCS_WITHOUT_TOKEN_WITH_TENANTS } from "../../../../../../services/APIService";
import { Add, Dehaze, Search } from "@mui/icons-material";
import HealthDeptIcon from "../../../../../../icons/Healthcare_Department.svg";
import ParticularPublicOverviewDept from "./particularPublicOverviewDepts";
const ParticularPublicDeptsInfo = ({ selectedItemOfDept }) => {




    const [selectedItem, setSelectedItem] = useState(null);
    const [listOfHealthDepts, setListOfHealthDepts] = useState([]);
    const [filteredHealthDept, setFilteredHealthDept] = useState([]);
    const [value, setValue] = useState(null)


    const orgData = useSelector(state => state.registration.userOrg)



    useEffect(() => {
        if (orgData?.id) {
            getPublicDeptsList(orgData?.id);
        }
    }, [orgData?.id])

    useEffect(() => {
        if (value?.length > 0) {
            const filteredList = listOfHealthDepts.filter((dept) =>
                dept?.resource?.name?.toLowerCase().includes(value.toLowerCase()) ||
                dept?.resource?.alias?.[0]?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredHealthDept(filteredList);
        } else {
            setFilteredHealthDept(listOfHealthDepts); // Reset to full list when input length is less than 3
        }
    }, [value, listOfHealthDepts])



    useEffect(() => {
        if (selectedItemOfDept) {
            setSelectedItem(selectedItemOfDept)
        }
    }, [selectedItemOfDept])

    console.log(selectedItem, "selectedItem", listOfHealthDepts)

    // useEffect(() => {
    //     if (selectedItem?.id) {

    //     }

    // }, [selectedItem])





    const getPublicDeptsList = (orgId) => {

        if (orgId) {
            DOCS_WITHOUT_TOKEN_WITH_TENANTS.get(`${URI.getHealthCareDeptListWithOutToken}${orgId}`).then((res) => {
                if (res.data?.entry) {
                    setListOfHealthDepts(res.data?.entry);
                } else {
                    setListOfHealthDepts([]);
                }
            }).catch((err) => {
                setListOfHealthDepts([]);
                console.log(err)
            })
        }

    }




    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={3}
                    sx={{ height: "400px" }}

                >

                    <Card className="ph2" sx={{
                        marginTop: "0px",
                        borderRadius: "10px", height: { sm: "100%", md: "100%", lg: "100%" },
                        boxShadow: "0px 0px 1.6px #888",

                    }
                    } >
                        <Box className="mt2" sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="p" sx={{ fontSize: "18px", fontWeight: 600, color: "#3084BE", }}>Healthcare Department</Typography>
                            {/* <Box sx={{ display: "flex" }}>
                                <Dehaze sx={{ fontSize: "18px", margin: "3px 8px 0 0" }} />
                                <Box sx={{ width: "18px", height: '18px', borderRadius: "2px", border: '1px solid #888', marginTop: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Add />
                                </Box>
                            </Box> */}
                        </Box>

                        <Box className="mt2" sx={{
                            width: "96%", padding: "0 4px", height: '36px', border: "1px solid #e0e0e0", borderRadius: "10px",
                            display: "flex", alignItems: "center"
                        }}>
                            <Search sx={{ fontSize: "30px" }} />
                            <TextField
                                id="name"
                                sx={{
                                    width: "85%",
                                    margin: "0 0 0px 6px",
                                    "& .MuiInput-underline:before": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                        borderBottom: "none",
                                    }
                                }}
                                value={value || ""}
                                onChange={(event) => {
                                    console.log(event.target.value, "900")
                                    setValue(event.target.value)
                                }}
                                placeholder="Search Healthcare Department"
                                type="text"
                                variant="standard"
                            />
                        </Box>

                        <Box sx={{

                            overflowY: 'scroll',
                            mt: 1,
                            height: { xs: '100vh', sm: '100vh', md: '38vh' },
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            }
                        }}>
                            {
                                filteredHealthDept?.length > 0 && filteredHealthDept?.map((val) => {

                                    return (

                                        <Card sx={{
                                            width: "94%", marginLeft: "2px",
                                            padding: "5px 5px 0 6px", borderRadius: "", height: { sm: "60px", md: "60px", lg: "60px" },
                                            backgroundColor: selectedItem?.id === val?.resource?.id ? "lightblue" : "none",
                                            boxShadow: "0px 0px 1.6px #888", display: "flex", alignItems: "center",
                                            mb: 2
                                        }} className='mt1 cursor'
                                            onClick={() => {
                                                // handleDepartment(val?.resource)
                                                setSelectedItem(val?.resource)
                                            }}
                                        >

                                            <div style={{ width: "100%", display: "flex", gap: "10px", alignItems: "center" }}>
                                                <div>
                                                    <Avatar variant="square" sx={{
                                                        width: '40px !important', height: '40px !important',

                                                    }}
                                                        src={val?.resource?.identifier?.filter((val) => val["system"] == `${process.env.REACT_APP_API_URL}v1/logo` && val["value"]?.startsWith("https"))?.[0]?.value || HealthDeptIcon} />
                                                </div>

                                                <div style={{ height: "40px", width: "100%" }}>
                                                    <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.name}</div>
                                                    <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.alias?.[0]}</div>
                                                </div>
                                            </div>
                                        </Card>

                                    )
                                }
                                )
                            }
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={9}>
                    {
                        selectedItem &&
                        (
                            <ParticularPublicOverviewDept departmentId={selectedItem} />
                        )
                    }
                </Grid>

            </Grid>
        </div>

    )
}

export default ParticularPublicDeptsInfo;